<div id="appCapsule">
  <div class="section full mt-2 mb-2">

    <div class="appHeader bg-primary">
      <div class="pageTitle">
        <div class="section-title text-white">{{productDetail.label}}</div>
      </div>

      <div class="right exitBtn" (click)="exitModal()">
        <ion-icon name="close"></ion-icon>
      </div>
    </div>


    <div class="img-block pt-2 ">
      <div>
        <img *ngIf="productDetail.image !== null" src="{{path}}/input/produits/{{productDetail.image}}" alt="image"
          class="imaged img-fluid">
        <img *ngIf="productDetail.image === null" src="assets/img/default-picture.jpg" class="image"
          alt="{{product.label}}">
      </div>

    </div>

    <div class="wide-block pt-2 pb-2">

      <div class="section full">
        <div class="wide-block pt-2 pb-2 product-detail-header"
          style="justify-content: center; display: flex; border-top: none">
          <div>
            <div class="rate-block mb-1">
              <ion-icon name="star" [class]="productDetail.rate >= 1 ? 'active' : null"></ion-icon>
              <ion-icon name="star" [class]="productDetail.rate >= 2 ? 'active' : null"></ion-icon>
              <ion-icon name="star" [class]="productDetail.rate >= 3 ? 'active' : null"></ion-icon>
              <ion-icon name="star" [class]="productDetail.rate >= 4 ? 'active' : null"></ion-icon>
              <ion-icon name="star" [class]="productDetail.rate >= 5 ? 'active' : null"></ion-icon>
            </div>
            <div class="rate-block" style="justify-content: center; font-size: 12px">
              {{"count-rating" | transloco: {count: productDetail.count_rate} }}</div>
          </div>

        </div>

        <div class="wide-block pt-2 pb-2 product-detail-header"
          style="justify-content: center; display: flex; border-top: none">
          <div class="row" style="justify-content: center; display: flex;">
            <ng-container *ngFor="let allergen of productDetail.allergens">
              <div class="mr-2 ml-2" *ngIf="allergen.value === true" style="width: 40px !important">
                <img src="assets/img/allergen/{{getImgOfAllergen(allergen.allergen_id)}}"
                  style="width: 40px !important">
              </div>
            </ng-container>
            <ng-container *ngIf="productDetail.allergens.length === 0">
              <div class="col-12">
                <strong>{{"no-allergens" | transloco}}</strong>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="section full mt-2">
        <div class="section-title">Meal Description</div>
        <div class="wide-block pt-2 pb-2" *ngIf="productDetail.description !== null">
          {{productDetail.description}}
        </div>
        <div class="wide-block pt-2 pb-2" *ngIf="productDetail.description === null">
          <strong>{{"no-description-product" | transloco}}</strong>
        </div>
      </div>

      <div class="section full mt-2">
        <div class="section-title">Meal Ingredient</div>
        <div class="wide-block pt-2 pb-2" *ngIf="productDetail.ingredient !== null" style="text-align: center">
          {{productDetail.ingredient}}
        </div>
        <div class="wide-block pt-2 pb-2" *ngIf="productDetail.ingredient === null" style="text-align: center">
          <strong>{{"no-ingredient-product" | transloco}}</strong>
        </div>
      </div>

      <div class="section full mt-2">
        <div class="section-title">Manage ratings</div>
        <div class="wide-block pt-2 pb-2">
          <div class="rate-block mb-1" style="justify-content: center; display: flex; border-top: none">
            <ion-icon name="star" class="icon-rate" [class]="productDetail.rate_user >= 1 ? 'active' : null"
              (click)="updateRate(1)">
            </ion-icon>
            <ion-icon name="star" class="icon-rate" [class]="productDetail.rate_user >= 2 ? 'active' : null"
              (click)="updateRate(2)">
            </ion-icon>
            <ion-icon name="star" class="icon-rate" [class]="productDetail.rate_user >= 3 ? 'active' : null"
              (click)="updateRate(3)">
            </ion-icon>
            <ion-icon name="star" class="icon-rate" [class]="productDetail.rate_user >= 4 ? 'active' : null"
              (click)="updateRate(4)">
            </ion-icon>
            <ion-icon name="star" class="icon-rate" [class]="productDetail.rate_user >= 5 ? 'active' : null"
              (click)="updateRate(5)">
            </ion-icon>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>
