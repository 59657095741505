import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  BehaviorSubject,
  filter,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { ICategory } from './products.info.types';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private _product: BehaviorSubject<ICategory> = new BehaviorSubject(null);
  private _products: BehaviorSubject<ICategory[]> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors link to SMS side
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for Products
   */
  get products$(): Observable<ICategory[]> {
    return this._products.asObservable();
  }

  /**
   * Getter for Product
   */
  get product$(): Observable<ICategory> {
    return this._product.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors link to Emails side
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods link to SMS side
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get Products
   */
  getProducts(): Observable<ICategory[]> {
    return this._httpClient
      .get<ICategory[]>(
        environment.path + '/api/interne/crewmeals/v1/products',
        {}
      )
      .pipe(
        tap((elements) => {
          this._products.next(elements);
        })
      );
  }

  updateRateOfProduct(rate: number, product_id: number): Observable<boolean> {
    return this.products$.pipe(
      take(1),
      switchMap((products) =>
        this._httpClient
          .put(environment.path + '/api/interne/crewmeals/v1/products', {
            rate,
            product_id,
          })
          .pipe(
            map(
              (response: {
                rate: number;
                rate_user: number;
                count_rate: number;
              }) => {
                let category_index = null;
                let product_index = null;
                products.forEach((e, index) => {
                  let check = e.products.findIndex(
                    (f) => f.product_id === product_id
                  );
                  if (check !== -1) {
                    category_index = index;
                    product_index = check;
                  }
                });
                products[category_index].products[product_index] = {
                  ...products[category_index].products[product_index],
                  rate: response.rate,
                  rate_user: response.rate_user,
                  count_rate: response.count_rate,
                };
                this._products.next(products);
                return true;
              }
            )
          )
      )
    );
  }
}
