import { Injectable } from '@angular/core';
import {
  catchError,
  Observable,
  of,
  switchMap,
  tap,
  throwError,
  delay,
  filter,
  BehaviorSubject,
  fromEvent,
  takeUntil,
  map,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  public _scrollPosition: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() {}

  get scrollPosition$(): Observable<boolean> {
    return this._scrollPosition.asObservable();
  }
  getHeaderStatus(): Observable<boolean> {
    return this._scrollPosition.asObservable().pipe(tap((isShow) => isShow));
  }
}
