import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ICategory, IProduct } from '../../products-info/products.info.types';
import { AllergensService } from 'app/core/allergens/allergens.service';
import { Subject, take, takeUntil } from 'rxjs';
import { IAllergen } from 'app/core/allergens/allergens.types';
import { ProductService } from '../../products-info/products.info.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'details-meals-layout',
  templateUrl: './details.meals.component.html',
  styleUrls: ['./details.meals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsMealsComponent {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  allergens: IAllergen[] = [];
  categoryDetail: ICategory;
  productDetail: IProduct;
  path: string = environment.path;

  constructor(
    public _dialogRef: MatDialogRef<DetailsMealsComponent>,
    @Inject(MAT_DIALOG_DATA)
    public ids: {
      product_id: number;
      category_id: number;
    },
    public _allergensService: AllergensService,
    public _productService: ProductService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._allergensService.allergens$
      .pipe(takeUntil(this._unsubscribeAll), take(1))
      .subscribe((e) => {
        this.allergens = e;
      });
    this._productService.products$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((e) => {
        this.categoryDetail = e.find(
          (e) => e.category_id === this.ids.category_id
        );
        this.productDetail = this.categoryDetail.products.find(
          (e) => e.product_id === this.ids.product_id
        );
        this._changeDetectorRef.markForCheck();
      });
  }
  /**
   * On Destroy
   */
  ngOnDestroy() {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  exitModal() {
    this._dialogRef.close();
  }

  getImgOfAllergen(id: number): string {
    return this.allergens.find((e) => e.id === id).img;
  }

  updateRate(rate: number): void {
    this._productService
      .updateRateOfProduct(rate, this.productDetail.product_id)
      .subscribe(() => {
        this._changeDetectorRef.markForCheck();
      });
  }
}
