import { Injectable } from '@angular/core';
import { filter } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  private history: any[] = [];

  /**
   * Constructor
   */
  constructor(private _router: Router) {}

  init() {
    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(({ urlAfterRedirects }: any) => {
        this.history = [...this.history.reverse(), urlAfterRedirects]
          .reverse()
          .slice(0, 10);
      });
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '/';
  }
}
