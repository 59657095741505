<ng-container>
  <div class="modal fade panelbox panelbox-left" id="sidebarPanel" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="profileBox">
            <div class="in">
              <strong>{{user?.firstname}} {{user?.lastname}}</strong>
              <div class="text-muted">
                <ion-icon name="people"></ion-icon>
                Airarabia Member
              </div>
            </div>
            <a href="javascript:;" class="close-sidebar-button" data-dismiss="modal">
              <ion-icon name="close"></ion-icon>
            </a>
          </div>
          <ul class="listview flush transparent no-line image-listview mt-2">
            <li>
              <a href="#home" class="item" routerLink="/" data-dismiss="modal">
                <div class="icon-box bg-primary">
                  <ion-icon name="home"></ion-icon>
                </div>
                <div class="in">
                  Home
                </div>
              </a>
            </li>
            <li>
              <a href="#" class="item" routerLink="/products-list" data-dismiss="modal">
                <div class="icon-box bg-primary">
                  <ion-icon name="fast-food-outline"></ion-icon>
                </div>
                <div class="in">
                  Products information
                </div>
              </a>
            </li>
            <li>
              <a href="#" class="item" routerLink="/next-orders" data-dismiss="modal">
                <div class="icon-box bg-primary">
                  <ion-icon name="basket"></ion-icon>
                </div>
                <div class="in">
                  Next Orders
                </div>
              </a>
            </li>
            <li>
              <a href="#" class="item" routerLink="/orders-history" data-dismiss="modal">
                <div class="icon-box bg-primary">
                  <ion-icon name="cloud-circle-outline"></ion-icon>
                </div>
                <div class="in">
                  Orders history
                </div>
              </a>
            </li>
            <!--
            
                        <li>
              <a href="#home" class="item" routerLink="/history-list" data-dismiss="modal">
                <div class="icon-box bg-primary">
                  <ion-icon name="calendar"></ion-icon>
                </div>
                <div class="in">
                  <div>Orders history</div>
                </div>
              </a>
            </li>
            <li>
              <a href="page-chat.html" class="item">
                <div class="icon-box bg-primary">
                  <ion-icon name="chatbubble-ellipses-outline"></ion-icon>
                </div>
                <div class="in">
                  <div>Support</div>
                  <span class="badge badge-danger">5</span>
                </div>
              </a>
            </li>
            
            -->

          </ul>

        </div>

        <div class="sidebar-buttons">
          <a href="#" class="button" routerLink="/account" data-dismiss="modal">
            <ion-icon name="settings-outline"></ion-icon>
          </a>
          <a href="javascript:;" class="button" (click)="showAlert()" data-dismiss="modal">
            <ion-icon name="log-out-outline"></ion-icon>
          </a>
        </div>
      </div>
    </div>
  </div>

</ng-container>
