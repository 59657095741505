import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import {
  combineLatest,
  delay,
  forkJoin,
  map,
  Observable,
  of,
  switchMap,
} from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { HistoryService } from 'app/core/history/history.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  public isCheckAuthGuard: boolean = false;

  /**
   * Constructor
   */
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _historyService: HistoryService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.isCheckAuthGuard) {
      this.isCheckAuthGuard = true;
      const redirectUrl = state.url === '/logout' ? '/' : state.url;
      return this._check(redirectUrl);
    } else {
      return true;
    }
  }

  /**
   * Can activate child
   *
   * @param childRoute
   * @param state
   */
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this.isCheckAuthGuard) {
      this.isCheckAuthGuard = true;
      const redirectUrl = state.url === '/logout' ? '/' : state.url;
      return this._check(redirectUrl);
    } else {
      return true;
    }
  }

  /**
   * Can load
   *
   * @param route
   * @param segments
   */
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._check('/');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the authenticated status
   *
   * @param segments
   * @private
   */
  private _check(redirectURL: string): Observable<any> {
    const getLastestRoute = this._historyService
      .getHistory()
      .filter((e) => !e.includes('logout') && !e.includes('login'));

    const checkAuth = this._authService.check();

    return combineLatest([checkAuth]).pipe(
      map(([checkAuth]) => {
        setTimeout(() => {
          this.isCheckAuthGuard = false;
        }, 10);

        if (!checkAuth) {
          redirectURL =
            getLastestRoute.length > 0 ? getLastestRoute[0] : redirectURL;
          this._router.navigate(['login']);
          return of(false);
        } else {
          return of(true);
        }
      })
    );
  }
}
