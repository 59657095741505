import { ChangeDetectorRef, Component } from '@angular/core';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { Subject, takeUntil } from 'rxjs';
import { AlertController } from '@ionic/angular';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'slide-menu',
  templateUrl: './slide.menu.component.html',
  styleUrls: ['./slide.menu.component.scss'],
})
export class SlideMenuComponent {
  user: User | null = null;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _userService: UserService,
    private _changeDetectorRef: ChangeDetectorRef,
    public alertCtrl: AlertController,
    private _authService: AuthService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to user changes
    this._userService.user$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user: User) => {
        this.user = user;
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
  }

  async showAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Confirmation',
      message: 'Confirm the logout of your account ?',
      buttons: [
        {
          text: 'Confirm',
          role: 'confirm',
        },
        {
          text: 'Cancel',
          role: 'cancel',
        },
      ],
    });
    await alert.present();
    const result = await alert.onDidDismiss();
    if (result.role === 'confirm') {
      this._authService.logout();
    }
  }
}
