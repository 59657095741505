import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  catchError,
  Observable,
  of,
  switchMap,
  tap,
  throwError,
  delay,
  filter,
  BehaviorSubject,
} from 'rxjs';
// import { UserService } from 'app/core/user/user.service';
import { Router } from '@angular/router';
import { UserService } from '../user/user.service';
import { AuthUtils } from './auth.utils';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _authenticated: boolean = false;
  private crew_id: number;

  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService,
    private _router: Router
  ) {
    _userService.user$.subscribe((e) => {
      this.crew_id = e.id;
    });
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }
  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  disconnected(): void {
    localStorage.removeItem('accessToken');
    this._authenticated = false;
  }

  login(credentials: { login: string; password: string }): Observable<any> {
    if (this._authenticated) {
      return throwError('Vous êtes déjà connecté');
    }
    return this._httpClient
      .post(
        environment.path + '/api/interne/crewmeals/v1/login',
        {},
        {
          headers: {
            Authorization:
              'Basic ' + btoa(credentials.login + ':' + credentials.password),
          },
        }
      )
      .pipe(
        switchMap((response: any) => {
          this.accessToken = response.value;
          this._authenticated = true;
          this._userService.user = response.user;
          return of(response);
        })
      );
  }

  loginToken(): Observable<any> {
    return this._httpClient
      .post(environment.path + '/api/interne/crewmeals/v1/login-with-token', {
        accessToken: this.accessToken,
      })
      .pipe(
        catchError(() => of(false)),
        switchMap((response: any) => {
          if (response.accessToken) {
            this.accessToken = response.accessToken;
          }
          this._authenticated = true;
          this._userService.user = response.user;
          return of(true);
        })
      );
  }

  logout(): void {
    localStorage.removeItem('accessToken');
    this._authenticated = false;
    this._router.navigateByUrl('/login');
  }

  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    // If the access token exists and it didn't expire, sign in using it
    return this.loginToken();
  }

  firstConnection(credentials: {
    email: string;
    firstname: string;
    lastname: string;
    matricule: string;
  }): Observable<any> {
    return this._httpClient
      .post(environment.path + '/api/interne/crewmeals/v1/first-connection', {
        ...credentials,
      })
      .pipe(
        switchMap((response: boolean) => {
          return of(response);
        })
      );
  }

  forgotPassword(credentials: { email: string }): Observable<any> {
    return this._httpClient
      .put(environment.path + '/api/interne/crewmeals/v1/forgot-password', {
        ...credentials,
      })
      .pipe(
        switchMap((response: boolean) => {
          return of(response);
        })
      );
  }

  changePassword(credentials: {
    old_password: string;
    new_password: string;
  }): Observable<boolean> {
    return this._httpClient
      .put(environment.path + '/api/interne/crewmeals/v1/change-password', {
        old_password: btoa(credentials.old_password),
        new_password: btoa(credentials.new_password),
      })
      .pipe(
        switchMap((response: boolean) => {
          return of(response);
        })
      );
  }

  changeAllergens(choices: any): Observable<boolean> {
    return this._httpClient
      .put(
        environment.path +
          '/api/interne/crewmeals/v1/allergens/' +
          this.crew_id,
        {
          ...choices,
        }
      )
      .pipe(
        switchMap((response: boolean) => {
          return of(response);
        })
      );
  }

  changeTastes(choices: any): Observable<boolean> {
    return this._httpClient
      .put(
        environment.path + '/api/interne/crewmeals/v1/tastes/' + this.crew_id,
        {
          ...choices,
        }
      )
      .pipe(
        switchMap((response: boolean) => {
          return of(response);
        })
      );
  }
}
