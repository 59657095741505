import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LoadingCircleComponent } from 'app/core/components/loading-circle/loading-circle.component';

@NgModule({
  declarations: [LoadingCircleComponent],
  imports: [CommonModule, MatProgressBarModule],
  exports: [LoadingCircleComponent],
})
export class LoadingCircleModule {}
