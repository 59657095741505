import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
} from '@angular/core';
import { ScrollService } from 'app/core/scroll/scroll.service';
import { HistoryService } from 'app/core/history/history.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  public showHeader: boolean = false;
  @HostListener('scroll', ['$event']) onWindowScroll(e: any) {
    if (e?.currentTarget?.localName === 'app-root') {
      let scrollD = this.getYPosition(e);
      if (scrollD > 100) {
        this._scrollService._scrollPosition.next(true);
      } else {
        this._scrollService._scrollPosition.next(false);
      }
    }
  }

  constructor(
    private _scrollService: ScrollService,
    private _historyService: HistoryService
  ) {
    this._scrollService.scrollPosition$;
  }

  ngOnInit(): void {
    this._historyService.init();
  }

  getYPosition(e: Event): number {
    return (e.target as Element).scrollTop;
  }
}
