  <loading-circle></loading-circle>

  <slide-menu></slide-menu>

  <router-outlet></router-outlet>

  <!--

  <div class="appBottomMenu">
  <a href="index.html" class="item active">
    <div class="col">
      <ion-icon name="home-outline"></ion-icon>
    </div>
  </a>
  <a href="app-components.html" class="item">
    <div class="col">
      <ion-icon name="fast-food-outline"></ion-icon>
    </div>
  </a>
  <a href="javascript:;" class="item" data-toggle="modal" data-target="#sidebarPanel">
    <div class="col">
      <ion-icon name="basket"></ion-icon>
    </div>
  </a>
  <a href="app-pages.html" class="item">
    <div class="col">
      <ion-icon name="calendar"></ion-icon>
    </div>
  </a>
  <a href="page-chat.html" class="item">
    <div class="col">
      <ion-icon name="chatbubble-ellipses-outline"></ion-icon>
      <span class="badge badge-danger">5</span>
    </div>
  </a>
</div>

-->
