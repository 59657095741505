import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IonicModule } from '@ionic/angular';
import { TranslocoCoreModule } from './core/transloco/transloco.module';
import { ScrollService } from './core/scroll/scroll.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HistoryService } from 'app/core/history/history.service';
import { DetailsMealsComponent } from 'app/modules/admin/meals-choice/details/details.meals.component';
import { SlideMenuComponent } from './modules/admin/slide-menu/slide.menu.component';
import { LoadingModule } from './core/loading/loading.module';
import { LoadingCircleModule } from './core/components/loading-circle/loading-circle.module';
import { AuthService } from './core/auth/auth.service';
import { AuthInterceptor } from './core/auth/auth.interceptor';

@NgModule({
  declarations: [AppComponent, DetailsMealsComponent, SlideMenuComponent],
  exports: [],
  imports: [
    BrowserModule,
    AppRoutingModule,
    IonicModule.forRoot(),
    HttpClientModule,
    TranslocoCoreModule,
    BrowserAnimationsModule,
    LoadingModule,
    LoadingCircleModule,
  ],
  providers: [
    ScrollService,
    HistoryService,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
