import { Route } from '@angular/router';
// import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/guards/noAuth.guard';
import { AuthGuard } from './core/guards/auth.guard';
// import { LayoutComponent } from 'app/layout/layout.component';
// import { InitialDataResolver } from 'app/app.resolvers';

export const appRoutes: Route[] = [
  // Redirect empty path to '/example'
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('app/modules/admin/home/home.module').then(
            (m) => m.HomeModule
          ),
      },
      {
        path: 'book',
        loadChildren: () =>
          import('app/modules/admin/meals-choice/meals.choice.module').then(
            (m) => m.MealsChoiceModule
          ),
      },
      {
        path: 'products-list',
        loadChildren: () =>
          import('app/modules/admin/products-info/products.info.module').then(
            (m) => m.ProductsInfoModule
          ),
      },
      {
        path: 'history-list',
        loadChildren: () =>
          import('app/modules/admin/history-order/history.order.module').then(
            (m) => m.HistoryOrderModule
          ),
      },
      {
        path: 'next-orders',
        loadChildren: () =>
          import('app/modules/admin/next-order/next.order.module').then(
            (m) => m.NextOrderModule
          ),
      },
      {
        path: 'orders-history',
        loadChildren: () =>
          import('app/modules/admin/orders-history/orders-history.module').then(
            (m) => m.OrdersHistoryModule
          ),
      },
      {
        path: 'account',
        loadChildren: () =>
          import('app/modules/admin/account/account.module').then(
            (m) => m.AccountModule
          ),
      },
      {
        path: 'cycles',
        loadChildren: () =>
          import('app/modules/admin/cycle/cycle.module').then(
            (m) => m.CycleModule
          ),
      },
    ],
  },
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    children: [
      {
        path: 'login',
        loadChildren: () =>
          import('app/modules/auth/login/login.module').then(
            (m) => m.LoginModule
          ),
      },
      {
        path: 'first-connection',
        loadChildren: () =>
          import(
            'app/modules/auth/first-connection/first-connection.module'
          ).then((m) => m.FirstConnectionModule),
      },
      {
        path: 'forget',
        loadChildren: () =>
          import('app/modules/auth/forget/forget.module').then(
            (m) => m.ForgetModule
          ),
      },
    ],
  },
  {
    path: 'error',
    children: [
      {
        path: '500',
        loadChildren: () =>
          import('app/modules/error/error-global/error-global.module').then(
            (m) => m.ErrorGlobalModule
          ),
      },
    ],
  },
  {
    path: '404-not-found',
    pathMatch: 'full',
    loadChildren: () =>
      import('app/modules/error/error-global/error-global.module').then(
        (m) => m.ErrorGlobalModule
      ),
  },
  { path: '**', redirectTo: '404-not-found' },
];
