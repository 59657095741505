import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Observable, ReplaySubject, tap } from 'rxjs';
import { IAllergen } from './allergens.types';

@Injectable({
  providedIn: 'root',
})
export class AllergensService {
  private _allergens: BehaviorSubject<IAllergen[]> = new BehaviorSubject<
    IAllergen[]
  >([
    {
      id: 1,
      label: 'gluten',
      img: 'gluten.png',
    },
    {
      id: 2,
      label: 'crustacean',
      img: 'crustacean.png',
    },
    {
      id: 3,
      label: 'eggs',
      img: 'eggs.png',
    },
    {
      id: 4,
      label: 'fish',
      img: 'fish.png',
    },
    {
      id: 5,
      label: 'peanut',
      img: 'peanut.png',
    },
    {
      id: 6,
      label: 'soy',
      img: 'soy.png',
    },
    {
      id: 7,
      label: 'milk',
      img: 'milk.png',
    },
    {
      id: 8,
      label: 'nuts',
      img: 'nuts.png',
    },
    {
      id: 9,
      label: 'celery',
      img: 'celery.png',
    },
    {
      id: 10,
      label: 'mustard',
      img: 'mustard.png',
    },
    {
      id: 11,
      label: 'sesame seed',
      img: 'alergen_ic.png',
    },
    {
      id: 12,
      label: 'sulfur dioxide',
      img: 'sulfur_dioxide.png',
    },
    {
      id: 13,
      label: 'lupine',
      img: 'lupine.png',
    },
    {
      id: 14,
      label: 'mollusc',
      img: 'mollusc.png',
    },
  ]);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  get allergens$(): Observable<IAllergen[]> {
    return this._allergens.asObservable();
  }
}
